.FrontPageBackground {
    display: flex;
    margin-top: 30px;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.FrontPageLayer {
    display: flex; 
    width: 70%; 
    justify-Content: space-between;
}

.FrontPageMeDescription {
    margin-Left: 40px; 
    margin-Right: 40px; 
    font-Size: 20px;
}

.FrontPageView {
    height: 100%;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50);
    text-align: center;
    border-radius: 25px;
    margin-bottom: 30px;
    background-color: #D8D9DE;
}

.FrontPageHeader {
    border-bottom: 1px solid black;
    margin: 40px;
    text-transform: uppercase;
}

.InformationHeader {
    border-bottom: 1px solid black;
    margin: 40px;
    margin-top: 100px;
    text-transform: uppercase;
}

.InformationContainer {
    margin-Right: 20px; 
    margin-Left: 20px; 
    display: flex; 
    flex-Wrap: wrap; 
    justify-Content: center;
}
.ContactContainer {
    margin-Right: 20px; 
    margin-Left: 20px; 
    display: flex; 
    flex-Wrap: wrap; 
    justify-Content: center;
}


.InformationDiv {
    display: flex;
    justify-content: center;
    border: 2px solid #00000025;
    border-radius: 25px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
}

.ElementTypeDiv {
    font-size: 20px;
    width: 150px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    padding-left: 10px;
    margin: 10px;
}

.RealElementDiv {
    font-size: 20px;
    width: 250px;
    text-align: left;
    margin: 10px;
}

.status {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    margin-right: 20px;
}

.StickyElement {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    height: 10px;
    width: auto;
    margin-left: 50px;
}


.Picture {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50);
    border-radius: 25px;
    height: 300px;
}

.LinkLogo {
    height: 50px;
    margin: 40px;
}

.SkillsAndToolsContainer {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50);
    border-radius: 25px;
    height: auto;
    background-color: #D8D9DE;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 5px;
}

.SkillsAndToolsHeader {
    border-bottom: 1px solid black;
    margin: 20px;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
}

.SkillsAndToolsElements {
    text-align: center
}


