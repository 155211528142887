.ExperiencePageBackground {
    display: flex;
    margin-top: 30px;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ExperiencePageLayer {
    width: 70%;
    align-items: center;
    text-align: center;
}

.ExperiencePageContainer {
    width: 100%;
    align-items: center;
    text-align: center;
}

.ExperiencePageView {
    height: 100%;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50);
    text-align: center;
    border-radius: 25px;
    margin-bottom: 30px;
    background-color: #D8D9DE;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ExperienceHeader {
    text-align: center;
    color: #D8D9DE;
    text-shadow: 1px 10px 20px #D8D9DE50, 0 0  25px #D8D9DE25;
    margin-bottom: 40px;
    text-transform: uppercase;
}



.TimeLineContainer {
    width: 20%;
}

.TimeLine {
    height: 100%;
    width: 1px;
    background-color: black;
}

.ExperienceInfoContainer {
    width: 50%;
}

.ExperienceInfoContent {
    margin: 40px;
}

.ExpereinceDates {
    text-Align: right; 
    font-Size: 20px;
}

.ExperienceFirm {
    text-Align: right; 
    font-Size: 20px;
}

.ExperiencePageDevider {
    height: 100px;
    width: 2px; 
    background-Color: #00000025; 
    margin: 20px;
}

.ExperienceDescriptionContainer {
    width: 50%;
}

.ExperienceDescriptionContent {
    margin: 40px; 
    text-Align: left; 
    font-Size: 20px;
}


