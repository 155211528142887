.TopBarLayer{
    display: flex; 
    margin-Top: 10px;
    justify-Content: center;
}


.TopBarView {
    height: 80px;
    width: 70%;
}

.TopBarContent {
    height: 100%;
    justify-content: space-between;
    display: flex;
    background-color: #D8D9DE;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50);
}

.LogoContainer {
    display: 'flex'; 
    align-Items: center;
    margin-Left: 50px;
}

.LogoContent {
    height: 50px; 
    margin: 10px;
}

.CVContainer {
    display: flex; 
    align-Items: center;
}

.CVContent {
    font-Size: 25px; 
    color: black; 
    font-Weight: 600;

}

.ButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.TopBarButtons {
    border: 2px solid #00000025;
    border-radius: 25px;
    margin-top: 10px;
    margin: 10px;
    font-size: 20px;
    padding: 10px;
    background-color: #D8D9DE;
    opacity: 1;
    cursor: pointer;
}

.TopBarButtons:hover {
    opacity: 0.5;
}

/* .DropDownBody {
    width: 270px;
    max-width : 100%;
}

.DropDownLi {
    z-index: 1;
    position: relative;
    margin-right: 50px;
    padding-right: 20px;
    padding-left: 20px;
    align-items: center;
    color: black;
    font-size: 25px;
    font-weight: 600;
    height: 80px;
    list-style-type: none;
    cursor: pointer;
    background: #D8D9DE;

    &:hover{
        background: #D8D9DE;
    }
} */

.ArrowDown {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #747474;
}

.ArrowUp {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #747474;
}